<!-- eslint-disable max-len -->
<template>
	<div class="min-height-screen h-full">
		<!-- complete goal -->
		<modal v-model="isSureToCompleteGoal" @close="goalId = null">
			<template #body>
				<div class="d-flex flex-column px-5 pb-5 mx-auto">
					<div class="mx-auto">
						<board-icon />
					</div>
					<h5 class="text-center text-h5 font-weight-bold mb-4">Congratulations</h5>
					<span class="text--secondary text-center text-base mb-4">
						Are you sure want to complete the goal?
					</span>

					<div class="d-flex align-center justify-center">
						<v-btn
							large
							rounded
							outlined
							type="button"
							color="primary"
							class="ma-2 font-weight-bold"
							@click="
								isSureToCompleteGoal = false
								goalId = null
							"
							>No</v-btn
						>
						<v-btn
							large
							rounded
							type="button"
							color="primary"
							class="ma-2 font-weight-bold"
							@click="
								isSureToCompleteGoal = false
								updateCompleteGoal()
							"
							>Yes</v-btn
						>
					</div>
				</div>
			</template>
		</modal>

		<v-card elevation="0" class="pb-5">
			<v-toolbar flat class="flex-grow-1">
				<v-toolbar-title>
					<strong class="text-theme">Goals</strong>
				</v-toolbar-title>
			</v-toolbar>

			<div class="px-3">
				<div
					v-if="isGoalsLoading"
					class="w-full h-full d-flex align-center justify-center"
					style="height: 400px"
				>
					<loader />
				</div>
				<div v-else>
					<!-- has goals -->
					<div v-if="hasGoals">
						<v-card
							v-for="goal in goals"
							:key="goal.id"
							flat
							elevation="0"
							class="pa-2 border-2 border--secondary mt-4 rounded-small"
						>
							<div class="pa-2 d-grid grid-cols-2 align-start">
								<div class="">
									<h3 class="text-base font-weight-bold mb-0 leading-normal">
										{{ goal.goalName }}
									</h3>
									<span class="text--secondary text-sm">{{ goal.goalSet }}</span>
								</div>
								<div class="justify-self-end">
									<button
										type="button"
										class="d-inline-block rounded-small border-2 py-1 px-3 text-sm"
										:class="{
											'border--primary font-weight-bold cursor-default': goal.isCompleted,
											'border--secondary cursor-pointer': !goal.isCompleted,
										}"
										@click="handleCompleteGoal(goal)"
									>
										<span v-if="goal.isCompleted" class="mr-2"><check-icon /></span>
										<span class="primary--text">{{
											goal.isCompleted ? 'Completed' : 'Complete'
										}}</span>
									</button>
								</div>
							</div>

							<a
								class="font-weight-bold px-2 py-2 d-inline-block text-sm text-decoration-none text-uppercase"
								:href="`${baseUrl}/products/goal/${goal.id}?token=${authToken}`"
								><strong>Suggest Product</strong>
							</a>
						</v-card>
					</div>
					<div v-else class="d-flex flex-column justify-center align-center" style="height: 400px">
						<span class="text-base text-secondary mb-3">Looks like you don't have any goals.</span>
					</div>
				</div>
			</div>
		</v-card>

		<v-card elevation="0" class="py-5">
			<div class="pa-3">
				<v-toolbar-title><strong>Popular Products</strong></v-toolbar-title>
			</div>

			<div class="px-2">
				<div class="">
					<div
						v-if="isPopularProductsLoading"
						class="w-full h-full d-flex align-center justify-center"
						style="height: 200px"
					>
						<loader />
					</div>
					<div v-else>
						<!-- has Popular products -->
						<div v-if="hasPopularProducts" class="d-flex px-1 overflow-x-auto space-x-4">
							<a
								v-for="product in popularProducts"
								:key="product.id"
								:href="`${baseUrl}/product/${product.id}?token=${authToken}`"
								class="text-decoration-none"
							>
								<v-card flat elevation="0">
									<div class="mb-2" style="width: min-content">
										<div class="pa-3 border-2 border--secondary rounded-small">
											<v-avatar size="78" tile v-if="product.imageUrl">
												<v-img
													v-if="product.imageUrl.startsWith('http')"
													class="rounded"
													:lazy-src="product.imageUrl"
													:src="product.imageUrl"
												/>
												<v-img
													v-else
													class="rounded"
													:lazy-src="`${IMAGE_BASE_URL}/${product.imageUrl}`"
													:src="`${IMAGE_BASE_URL}/${product.imageUrl}`"
												/>
											</v-avatar>
											<v-avatar v-else size="78" tile>
												<v-img
													class="rounded"
													lazy-src="/placeholder-image.png"
													src="/placeholder-image.png"
												/>
											</v-avatar>
										</div>
										<p class="mb-0 mt-1 truncate-text-2 leading-normal text-base font-weight-bold">
											{{ product.name }}
										</p>
										<div class="d-flex align-center flex-wrap">
											<span class="primary--text font-weight-bold text-sm mt-1 mr-2">
												{{
													product.dealPrice !== null && product.dealPrice > 0
														? currencyFormatter.format(product.dealPrice)
														: currencyFormatter.format(product.unitCost)
												}}
											</span>
											<span
												v-if="product.dealPrice !== null && product.dealPrice > 0"
												class="text-decoration-line-through text--secondary text-xs"
												>{{ currencyFormatter.format(product.unitCost) }}</span
											>
										</div>
									</div>
								</v-card>
							</a>
						</div>
						<div
							v-else
							class="w-full h-full d-flex align-center justify-center"
							style="height: 200px"
						>
							<span class="text-base text-secondary">No popular products found.</span>
						</div>
					</div>
				</div>
			</div>
		</v-card>
	</div>
</template>

<script>
import AuthMixin from '@/mixins/auth'
import Loader from '@/components/Loader.vue'
import CheckIcon from '@/components/icons/CheckIcon.vue'
import currencyFormatter from '@/utils/currency-formatter'
import { getAllGoals, completeGoal } from '@/services/goal'
import { getPopularProducts } from '@/services/products'
import { IMAGE_BASE_URL } from '@/constants'
import { SUCCESS } from '@/constants/status-code'

const Modal = () => import('@/components/Modal.vue')
const BoardIcon = () => import('@/components/icons/BoardIcon.vue')

export default {
	name: 'Home',
	mixins: [AuthMixin],
	components: {
		Loader,
		Modal,
		BoardIcon,
		CheckIcon,
	},
	data() {
		return {
			IMAGE_BASE_URL,
			currencyFormatter,
			isGoalsLoading: false,
			isPopularProductsLoading: false,
			isSureToCompleteGoal: false,
			popularProducts: [],
			goals: [],
			goalId: null,
		}
	},
	created() {
		Promise.all([this.fetchGoals(), this.fetchPopularProducts()])
	},
	computed: {
		hasGoals() {
			return Array.isArray(this.goals) && this.goals.length
		},
		hasPopularProducts() {
			return Array.isArray(this.popularProducts) && this.popularProducts.length
		},
	},
	methods: {
		handleCompleteGoal(editGoal) {
			if (editGoal.isCompleted) return
			const goal = { ...editGoal }
			this.goalId = goal.id
			this.isSureToCompleteGoal = true
		},
		async fetchGoals() {
			this.isGoalsLoading = true
			try {
				const res = await getAllGoals(this.authToken)
				const { data, statusCode } = res.data

				if (statusCode === SUCCESS) {
					if (Array.isArray(data) && data.length) {
						this.goals = data
					}
				}
			} catch (e) {
				console.log(e, 'error')
			}
			this.isGoalsLoading = false
		},
		async fetchPopularProducts() {
			this.isPopularProductsLoading = true
			const params = {
				searchText: '',
				page: 1,
				pageSize: 5,
			}

			try {
				const res = await getPopularProducts(this.authToken, { params })
				const { data, statusCode } = res.data

				if (statusCode === SUCCESS) {
					this.popularProducts = data.result
				}
			} catch (e) {
				console.log(e, 'error')
			}
			this.isPopularProductsLoading = false
		},
		async updateCompleteGoal() {
			try {
				const res = await completeGoal(this.authToken, { goalId: this.goalId })
				const { statusCode } = res.data

				if (statusCode === SUCCESS) {
					// this.fetchGoals()
					// eslint-disable-next-line no-param-reassign
					this.goals = this.goals.map((goal) => {
						if (goal.id === this.goalId) {
							return { ...goal, isCompleted: true }
						}
						return goal
					})
				}
			} catch (e) {
				console.log(e, 'error')
			}
			this.goalId = null
		},
	},
}
</script>

<style lang="scss" scoped>
.d-grid {
	display: grid;
}

.grid-cols-2 {
	grid-template-columns: repeat(2, minmax(0, auto));
}

.justify-self-end {
	justify-self: end;
}

.space-x-4 > * + * {
	margin-left: 1rem;
}
</style>
